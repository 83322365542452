import React from "react";
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { Auth } from "aws-amplify";

// import LoginPage from './LoginPage'
import LoginPage from "./LoginPage";
import SignupPage from "./SignupPage";
import ResetPassword from "./ResetPasswordPage";

import Dashboard from "./Dashboard";
import { Amplify } from "aws-amplify";
import WebViewerPage from "./web_viewer/App";
import { AppViewProvider } from "./web_viewer/container/app-view-state";
import {
  AUTH_STATE,
  getInitAuthProgress,
  getRefreshTokenInfo,
  signInWithRefreshToken,
} from "./lib/auth";

// import { getDentist } from './Dentists';

const Cache = Amplify.Cache;

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authProgress: getInitAuthProgress(),
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
  }

  componentDidMount() {
    const { authProgress } = this.state;
    if (authProgress === AUTH_STATE.CHECKING) {
      this.loginByRefreshToken();
    } else {
      Auth.currentAuthenticatedUser()
        .then(async (user) => {
          if (!user.attributes["custom:user_type"])
            user.attributes["custom:user_type"] = "Dentist";
          Amplify.Cache.setItem("user", user.attributes);
        })
        .catch((err) => {
          Cache.clear();
          this.props.history.push("/login", { from: this.props.location });
        });
    }
    this.unlisten = this.props.history.listen(() => {
      Auth.currentAuthenticatedUser()
        .then((user) => {})
        .catch(() => {
          Cache.clear();
        });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  async loginByRefreshToken() {
    const { refreshToken, username } = getRefreshTokenInfo();
    console.log("loginByRefreshToken");
    await signInWithRefreshToken(refreshToken, username);
    this.setState({
      authProgress: AUTH_STATE.SUCCESS,
    });
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { authProgress } = this.state;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (authProgress === AUTH_STATE.SUCCESS) {
            return <Component {...props} />;
          } else if (authProgress === AUTH_STATE.FAIL) {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location ? props.location : "/" },
                }}
              />
            );
          } else {
            // AUTH_STATE.CHECKING
            return (
              <div className="w-full h-full flex items-center justify-center">
                {/* <div onClick={this.onTest}>Test</div> */}
              </div>
            );
          }
        }}
      />
    );
  }
}

const WebViewer = (props) => (
  <AppViewProvider>
    <WebViewerPage {...props} />
  </AppViewProvider>
);

PrivateRoute = withRouter(PrivateRoute);
// const PublicRoute = withRouter(Route);
const PublicRoute = Route;
const Routes = () => (
  <Router>
    <Switch>
      <PublicRoute path="/login" component={LoginPage} />
      <PublicRoute path="/resetpassword" component={ResetPassword} />
      <PublicRoute path="/signup" component={SignupPage} />
      <PrivateRoute exact path="/" component={Dashboard} />
      <PrivateRoute exact path="/settings" component={Dashboard} />
      <PrivateRoute exact path="/billing" component={Dashboard} />
      <PrivateRoute path="/patients/:num" component={Dashboard} />
      <PrivateRoute path="/create-patient" component={Dashboard} />
      <PrivateRoute path="/edit-patient/:num" component={Dashboard} />
      <PrivateRoute
        exact
        path="/threedview/:userid/:patientid/:file/:name"
        component={WebViewer}
      />
      <PublicRoute
        exact
        path="/threedview/:file/:name/:interface_type"
        component={WebViewer}
      />
      {/* <PrivateRoute exact path="/viewer/:fileid/:" component={WebViewer}/> */}
    </Switch>
  </Router>
);

export default Routes;
