import { CognitoRefreshToken } from "amazon-cognito-identity-js";
import { Cache, Auth } from "aws-amplify";
import Cookies from "js-cookie";

export const AUTH_STATE = {
  CHECKING: 0,
  SUCCESS: 1,
  FAIL: 2,
};

export const getRefreshTokenInfo = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const data = params.get("data");

  if (!data) {
    return {
      refreshToken: undefined,
      username: undefined,
    };
  }

  const payload = JSON.parse(atob(data));

  const { refreshToken, username } = payload;

  return {
    refreshToken,
    username,
  };
};

export const signInWithRefreshToken = (refreshToken, username) => {
  return new Promise((resolve, reject) => {
    const cognitoRefreshToken = new CognitoRefreshToken({
      RefreshToken: refreshToken,
    });
    const user = Auth.createCognitoUser(username);
    user.refreshSession(cognitoRefreshToken, (err, res) => {
      if (err) {
        reject(err);
      } else {
        resolve(res);
        Cache.setItem("isAuthenticated", true);
      }
    });
  });
};

export const getInitAuthProgress = () => {
  const isAuthenticated = Cache.getItem("isAuthenticated");
  const { refreshToken, username } = getRefreshTokenInfo();

  if (isAuthenticated) {
    return AUTH_STATE.SUCCESS;
  }

  if (refreshToken && username) {
    return AUTH_STATE.CHECKING;
  }

  return AUTH_STATE.FAIL;
};
